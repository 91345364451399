<template>
  <b-modal
    v-slot="{ close }"
    :visible="visible"
    dialog-class="carousel-dialog"
    modal-class="carousel-modal"
    content-class="carousel-modal-content"
    hide-header
    hide-footer
    centered
    @show="handleBeforeShow"
    @hide="handleBeforeHide"
    @change="$emit('update:visible', $event)"
  >
    <button-close class="close-btn" @click="close" />
    <div @click="handleCarouselClick">
      <b-carousel
        ref="carousel"
        class="photos-carousel"
        :value="value"
        fade
        :controls="controls ? true : false"
        :interval="0"
        no-animation
        @input="controls?$emit('update:value', $event):''"
      >
        <b-carousel-slide
          v-for="(image, index) in images"
          :key="index"
          :img-src="image"
        />
      </b-carousel>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCarousel,
  BCarouselSlide,
} from 'bootstrap-vue';
import { getImageResource } from '@core/utils/image-utils';
import ButtonClose from '@core/components/buttons/ButtonClose.vue';

export default {
  name: 'PhotosCarouselModal',
  components: {
    BModal,
    ButtonClose,
    BCarousel,
    BCarouselSlide,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: Boolean,
    value: {
      type: Number,
      required: true,
    },
    photos: {
      type: Array,
      default: () => [],
    },
    controls:{
      type: Boolean,
      default:true
    }
  },

  computed: {
    images() {
      return this.photos.map((photo) => getImageResource(photo.url));
    },
  },
  methods: {
    handleCarouselClick(event) {
      if (event.srcElement.tagName !== 'IMG') {
        this.$emit('update:visible', false);
      }
    },
    handleKeyUp(event) {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }

      if (event.keyCode === 37) {
        this.$refs.carousel.prev();
      }
      if (event.keyCode === 39) {
        this.$refs.carousel.next();
      }
    },
    handleBeforeShow() {
      document.addEventListener('keyup', this.handleKeyUp);
    },
    handleBeforeHide() {
      document.removeEventListener('keyup', this.handleKeyUp);
    },
  },
};
</script>

<style lang="scss" type="text/css">
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "~@core/scss/base/components/_variables.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
@import '~@core/scss/base/bootstrap-include';

$calorusel-padding-y: 20px;
$calorusel-padding-x: 0px;
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  $calorusel-padding-y: 50px;
  $calorusel-padding-x: 100px;
}

.carousel-modal {
  .carousel-dialog {
    // streach the dialog to cover all the screen
    max-width: calc(100% - #{$calorusel-padding-x * 2});
    height: calc(100vh - #{$calorusel-padding-y * 2});
    .carousel-modal-content {
      width: auto;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      // remove the modal's styles.
      background: none;
      box-shadow: none;
      .modal-body {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.photos-carousel {
  height: 100%;
  width: 100%;
  .carousel-inner {
    height: 100%;
    width: 100%;
    display: flex;
    .carousel-item {
      float: none;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
      img { // Using a tag, as bootstrap does not give the option to use a custom class
        max-height: calc(100vh - #{$calorusel-padding-y * 2});
        height: auto;
        width: auto !important; // to override bootstrap's defailt
      }
    }
  }
  $buttom-width: 34px;
  $buttom-width-lg: 74px;

  .carousel-control-prev,
  .carousel-control-next {
    top: 50%;
    transform: translateY(-50%);
    width: $buttom-width;
    height: $buttom-width;
    @include media-breakpoint-up(lg) {
      width: $buttom-width-lg;
      height: $buttom-width-lg;
    }
  }
  .carousel-control-prev {
    left: 5%;
    transform: translate(-5%, -50%);
  }
  .carousel-control-next {
    right: 5%;
    transform: translate(5%, -50%);
  }

  // FIXME: vue-bootstrap doesn't let us use custom classes or slots for the icons
  // that is the reason that this is done in a manual way. Change to variables when available.
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    // from .btn-secondary
    background-color: $secondary;
    border: 1px solid $secondary;
    // from .btn-icon
    padding: .715rem .736rem;
    // from .btn
    text-align: center;
    // copied from horizontal-contaier .btn-next, .btn-previous
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 20px;
    width: $buttom-width;
    height: $buttom-width;
    @include media-breakpoint-up(lg) {
      background-size: 60px;
      width: $buttom-width-lg;
      height: $buttom-width-lg;
    }
  }
  .carousel-control-prev-icon {
    // copied from horizontal-contaier .btn-previous
    background-image: url(str-replace(str-replace($chevron-left, "currentColor", $white), "#", "%23"));
  }
  .carousel-control-next-icon {
    // copied from horizontal-contaier .btn-next
    background-image: url(str-replace(str-replace($chevron-right, "currentColor", $white), "#", "%23"));
  }
}
</style>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: 10px;
  right: 30px;
  opacity: 1;
  font-size: 2.75rem;
  z-index: 9999;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}
</style>
